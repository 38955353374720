<template>
  <section class="page-section" id="information">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase">Contact informatie</h2>
        <h3 class="section-subheading text-muted">Mocht u vragen hebben, neem gerust contact met mij op!</h3>
        <ul>
          <div>
                <span><i class="fas fa-mobile-alt"></i>
                    <b>Telefoon:</b> +31 6 31659164
                    </span>
            <br>
            <span><i class="fas fa-at"></i>
                    <b>E-mail:</b> Esther@kindercoaching-eigenwijsheid.nl
                    </span>
            <br>
            <span><i class="fas fa-map-marked-alt"></i>
                    <b>Adres:</b>  Buterheideveld 13, 8423TA Makkinga
                    </span>
          </div>
        </ul>
        <Map></Map>
      </div>
    </div>
  </section>
</template>

<script>
import Map from "@/components/Map";
export default {
  name: "Information",
  components: {Map}
}
</script>

<style scoped>
@import '../styles/styles.css';
</style>
