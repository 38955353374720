<template>
  <section class="page-section bg-light" id="portfolio">
    <div class="text-center title">
      <h2 class="section-heading text-uppercase">Foto's</h2>
    </div>
    <div class="container card-list">
      <silent-box class="gallery" :gallery="gallery"></silent-box>
    </div>
  </section>
</template>

<script>
export default {
  name: "Portfolio",
  data: function () {
    return {
      images: [
        {title: "", url: require('../assets/img/portfolio/01-full.jpeg')},
        {title: "", url: require('../assets/img/portfolio/02-full.jpeg')},
        {title: "", url: require('../assets/img/portfolio/03-full.jpeg')},
        {title: "", url: require('../assets/img/portfolio/04-full.jpeg')},
        {title: "", url: require('../assets/img/portfolio/05-full.jpeg')},
        {title: "", url: require('../assets/img/portfolio/06-full.jpeg')},
        {title: "", url: require('../assets/img/portfolio/07-full.jpeg')},
        {title: "", url: require('../assets/img/portfolio/08-full.jpeg')},
        {title: "", url: require('../assets/img/portfolio/09-full.jpeg')},
        {title: "", url: require('../assets/img/portfolio/10-full.jpeg')},
        {title: "", url: require('../assets/img/portfolio/11-full.jpeg')},


      ],
      gallery: [
        {
          src: require('../assets/img/portfolio/01-full.jpeg'),
          thumbnailWidth: '220px',
          thumbnailHeight: '220px',
        },
        {
          src: require('../assets/img/portfolio/02-full.jpeg'),
          thumbnailWidth: '220px',
          thumbnailHeight: '220px',
        },
        {
          src: require('../assets/img/portfolio/03-full.jpeg'),
          thumbnailWidth: '220px',
          thumbnailHeight: '220px'
        },
        {
          src: require('../assets/img/portfolio/04-full.jpeg'),
          thumbnailWidth: '220px',
          thumbnailHeight: '220px'
        },
        {
          src: require('../assets/img/portfolio/05-full.jpeg'),
          thumbnailWidth: '220px',
          thumbnailHeight: '220px'
        },
        {
          src: require('../assets/img/portfolio/06-full.jpeg'),
          thumbnailWidth: '220px',
          thumbnailHeight: '220px'
        },
        {
          src: require('../assets/img/portfolio/07-full.jpeg'),
          thumbnailWidth: '220px',
          thumbnailHeight: '220px'
        },
        {
          src: require('../assets/img/portfolio/08-full.jpeg'),
          thumbnailWidth: '220px',
          thumbnailHeight: '220px'
        },
        {
          src: require('../assets/img/portfolio/09-full.jpeg'),
          thumbnailWidth: '220px',
          thumbnailHeight: '220px'
        },
        {
          src: require('../assets/img/portfolio/10-full.jpeg'),
          thumbnailWidth: '220px',
          thumbnailHeight: '220px'
        },
        {
          src: require('../assets/img/portfolio/11-full.jpeg'),
          thumbnailWidth: '220px',
          thumbnailHeight: '220px'
        },
      ]
    }
  },
  methods: {},
}
</script>

<style>
@import '../styles/styles.css';

.title {
  margin-bottom: 5%;
}

.silentbox-item {
  margin: 8px;
}

img {
  border-radius: 0.2rem;
}

.gallery {
  box-sizing: border-box;
  margin: auto;
  align-items: center;
  display: block;
  text-align: center;
}

</style>

<style scoped>
</style>
