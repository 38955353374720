<template>
  <div>
    <NavBar></NavBar>
    <div class="container col-7" style="padding-bottom: 300px">
      <div class="text-center" style="margin-top: 5%">
        <h2 class="section-heading text-uppercase">Coachen met Seb als co-coach</h2>
      </div>
      <div class="align-items-center">
        <div class="team-member">
          <img class="mx-auto rounded-circle" src="../assets/img/team/5.png" alt=""/>
        </div>
      </div>
      <div>
        <h5>Inleiding</h5>
        <p class="text-muted">
          Vanaf 1 juli 2021 gaat Seb meewerken in mijn praktijk. Seb is een vriendelijke Berner sennenhond
          van 4 jaar. Samen hebben Seb en ik de afgelopen periode de opleiding Spiegelen/Coachen bij
          <a href="https://taketheleash.nl/tanja-van-doesburg/" style="color: #1176cf;">Tanja van Doesburg</a> gevolgd. Seb is in de
          coachsessies mijn co-coach.
          <br>
          <br>
          <b>Er zijn verschillende mogelijkheden om Seb in te zetten:</b>
        </p>
        <br>
        <div style="margin-left: 50px;">
          <h6>Coachsetting 1</h6>
          <p class="text-muted">
            In deze setting is Seb aanwezig tijdens de coachsessie en is hij vrij in zijn beweging en
            gedrag. Er wordt niets bewust van hem gevraagd. De interactie die ontstaat tussen de coachklant en
            de hond is vanuit vrijwel volledige vrijheid. Alles wat de coachklant ervaart door de aanwezigheid van
            de hond is interessante input in de sessie. Als coach zet ik dat wat er naar voren komt in ter
            ondersteuning van het leerproces van de coachee. Deze setting is vooral geschikt voor kinderen.
          </p>
          <br>
          <h6>Coachsetting 2</h6>
          <p class="text-muted">
            In deze setting worden de coachklant en Seb aan elkaar gekoppeld om bewuste
            interactie aan te gaan. De coachee wordt gevraagd een beroep te doen op de hond. De coach doet
            een stap terug om vanaf de zijlijn dit proces te observeren en te bevragen. De coach en de coachee
            hebben samen besproken aan welk ontwikkeldoel gewerkt gaat worden met behulp van de hond.
            Deze setting is ook zeer geschikt voor volwassenen.
          </p>
        </div>
        <br>
        <p class="text-muted">
          Veel mensen houden van honden. We worden geraakt door het contact met een hond. De
          aaibaarheid en de trouwe hondenogen maakt dat we gemakkelijker bij ons gevoel en ons zachte kant
          kunnen komen. Als we feedback krijgen middels het werken met een hond stellen we dit minder snel
          ter discussie. Immers met een hond kun je niet in discussie gaan. We zijn daardoor sneller geneigd
          om onszelf de vraag te stellen; wat doe ik waardoor de hond doet wat hij doet.
        </p>
        <br>
        <p class="text-info">
          <b-navbar-item style="padding: 0;">
            <router-link style="color: grey; padding: 0;" to="/#contact">Nieuwsgierig geworden? Neem gerust eens contact op.</router-link>
          </b-navbar-item>
          Hartelijke groet,
          Esther en Seb
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";

export default {
  name: "Portfolio",
  components: {NavBar}
}
</script>

<style scoped>
.team-member {
}
</style>
