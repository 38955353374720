<template>
  <router-view>
  </router-view>
</template>

<script>

export default {
  name: 'App',
  components: {},
  metaInfo: {
    title: 'Eigen Wijsheid',
    // all titles will be injected into this template
    titleTemplate: 'Eigen Wijsheid'
  },
  data: () => ({
    //
  }),
};
</script>
