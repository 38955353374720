<template>
  <div class="navbar-wrapper">
    <b-navbar :mobile-burger="true" class="is-primary">
      <template slot="brand" style="min-width: 300px">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img
              style="min-height: 80px"
              src="../assets/img/navbar-logo.png"
          >
        </b-navbar-item>
      </template>
      <template slot="end">
        <b-navbar-dropdown label="Hoofdpagina">
          <b-navbar-item>
            <router-link  class="dropdown-element" to="/#introduction">Introductie</router-link>
          </b-navbar-item>
          <b-navbar-item>
            <router-link  class="dropdown-element" to="/#portfolio">Foto's</router-link>
          </b-navbar-item>
          <b-navbar-item>
            <router-link  class="dropdown-element" to="/#team">Team</router-link>
          </b-navbar-item>
          <b-navbar-item>
            <router-link  class="dropdown-element" to="/#information">Contact</router-link>
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      pageLink: '',
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      console.log(window.innerWidth)
      if (window.innerWidth < 1024) {
        this.pageLink = `color: #4a4a4a`
      } else {
        this.pageLink = ``
      }
    }
  },
}
</script>

<style lang="scss">


// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your own stuff
$my-primary: #5B8F08;
$my-secondary: white;
$my-tertiary: #1176cf;
$link: white;
$colors: (
    "primary": ($my-primary, white)
);

$tabs-toggle-link-border-color: $my-primary;
$tabs-toggle-link-border-style: $my-primary;
$tabs-toggle-link-hover-background-color: $my-primary;
$tabs-toggle-link-hover-border-color: $my-primary;
$tabs-toggle-link-active-background-color: $my-primary;
$tabs-toggle-link-active-border-color: $my-primary;
$tabs-toggle-link-active-color: $link-invert !default;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

.hamburger {
  float: right;
}

.router-link-active {
  color: $my-tertiary;
  font-weight: bold;
}

.dropdown-element {
  color: #4a4a4a !important;
}

/*.nav-element {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  color: white;
}
 */
.navbar-wrapper {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

}
</style>
