<template>
  <header class="masthead">
    <div class="container">
      <div class="masthead-subheading">Welkom bij</div>
      <div class="masthead-heading text-uppercase"> Eigen Wijsheid</div>
      <a class="btn btn-primary btn-xl text-uppercase js-scroll-trigger" href="#services">Lees meer</a>
    </div>
  </header>
</template>

<script>
export default {
name: "Masthead"
}
</script>

<style scoped>
  @import '../styles/styles.css';

  .btn {
    color: #448aff
  }

</style>
