<template>
  <section class="page-section bg-light" id="team">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase">Mijn team</h2>
        <h3 class="section-subheading text-muted">Dit team ondersteunt mij tijdens mijn werk.</h3>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="team-member">
            <img class="mx-auto rounded-circle" src="../assets/img/team/1.png" alt=""/>
            <h4>Hulpvarken Suus</h4>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="team-member">
            <img class="mx-auto rounded-circle" src="../assets/img/team/2.png" alt=""/>
            <h4>Hulpkonijn Fenna</h4>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="team-member">
            <img class="mx-auto rounded-circle" src="../assets/img/team/3.jpeg" alt=""/>
            <h4>Hulpkonijn Foppe</h4>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="team-member">
            <img class="mx-auto rounded-circle" src="../assets/img/team/6.png" alt=""/>
            <h4>Hulphond-in-opleiding Ravi</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Team"
}
</script>

<style scoped>
@import '../styles/styles.css';
</style>
