<template>
  <section class="page-section" id="contact">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase">Neem contact op</h2>
        <h3 class="section-subheading text-muted">Laat hier een bericht achter.</h3>
      </div>
      <form id="contactForm" name="sentMessage" novalidate="novalidate">
        <div class="row align-items-stretch mb-5">
          <div class="col-md-6">
          </div>
        </div>
        <div class="text-center">
          <a class="btn btn-primary btn-xl text-uppercase"
             @click="sendEmail">
            Stuur een
            mail!
          </a>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactForm",
  data: function () {
    return {
      email: "esther@kindercoaching-eigenwijsheid.nl",
      subject: "Kinder Coaching"
    }
  },
  methods: {
    sendEmail: function () {
      const link = "mailto:" + this.email
          + "?subject=" + this.subject


      window.location.href = link
    }
  }
}
</script>

<style>

</style>
