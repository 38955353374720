<template>
  <div class="root">
    <NavBar></NavBar>
    <Masthead></Masthead>
    <Poem></Poem>
    <introduction></introduction>
    <Services></Services>
    <Portfolio></Portfolio>
    <Team></Team>
    <Information></Information>
    <ContactForm></ContactForm>
    <RadioFragment></RadioFragment>
    <Reference></Reference>
    <SocialMedia></SocialMedia>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Masthead from "@/components/Masthead";
import Introduction from "@/components/Introduction";
import Services from "@/components/Services";
import Portfolio from "@/components/Portfolio";
import Team from "@/components/Team";
import Poem from "@/components/Poem";
import Information from "@/components/Information";
import ContactForm from "@/components/ContactForm";
import Reference from "@/components/Reference";
import SocialMedia from "@/components/SocialMedia";
import RadioFragment from "@/components/RadioFragment";

export default {
  name: 'Home',
  components: {
    RadioFragment,
    SocialMedia,
    Reference,
    ContactForm,
    Information,
    Poem,
    Team,
    Portfolio,
    Services,
    Introduction,
    Masthead,
    NavBar
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Material+Icons");

  .root {
    scroll-behavior: smooth;
  }

  html {
    scroll-behavior: smooth;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
</style>
