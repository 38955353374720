<template>
  <section class="page-section" id="radiofragment">
    <div class="text-center">
      <h2 class="section-heading text-uppercase">Radiofragment Odrie</h2>
      <h3 class="section-subheading text-muted">In dit radiofragment praat ik met omroep Odrie over Eigen Wijsheid</h3>
    </div>
    <AudioPlayer file='../assets/audio/radiofragment_odrie.mp3'></AudioPlayer>
  </section>
</template>

<script>
import AudioPlayer from "@/components/AudioPlayer";

export default {
  name: "RadioFragment",
  components: {AudioPlayer},
}
</script>

<style scoped>

</style>
