<template>
  <section style="background:lightgray" class="page-section poem-container" id="poem">
    <div style="text-align: center; font-size: 24px; font-family: 'Comic Sans MS'" class="container">
      "Trek je<br>
      eigen plan<br>
      Ik weet dat<br>
      je dat kan<br>
      En als je even aarzelt<br>
      twijfelt<br>
      verkeerd stuurt<br>
      ben ik in de buurt"
    </div>
    <img class="decoration" src="../assets/img/decoration.svg" alt=""/>
  </section>
</template>

<script>

export default {
name: "Poem"
}
</script>

<style scoped>
  @import '../styles/styles.css';

  .decoration {
    width: 30rem;
    margin-top: 60px;
    color: grey;
  }

  .poem-container {
    text-align: center;
  }

  svg {
    fill: grey;
    stroke: green;
  }
</style>
