<template>
  <div class="container" style="margin: auto">
    <div style="padding: 20px; background-color: lightgray">
      <MglMap class="map" :center="center" :zoom="zoom" :accessToken="accessToken" :mapStyle="mapStyle">
        <MglMarker :coordinates="center" green="green"/>
      </MglMap>
    </div>
  </div>
</template>

<script>
import Mapbox from "mapbox-gl";
import {MglMap, MglMarker} from "vue-mapbox";

export default {
  components: {
    MglMap,
    MglMarker
  },
  name: "Map",

  data() {
    return {
      accessToken: "pk.eyJ1IjoibGVvbnZpcnVzIiwiYSI6ImNrZ25kbzk5ZzBmN2gyeW13Z3dyanUweDIifQ.GksPDMvlIUUFOtrXrNLFcg",
      mapStyle: "mapbox://styles/mapbox/satellite-streets-v11",
      center: [6.229460, 52.987380],
      zoom: 9
    };
  },
  created() {
    this.mapbox = Mapbox;
  }
}
</script>

<style scoped>
@import '../styles/styles.css';
@import url("https://api.tiles.mapbox.com/mapbox-gl-js/v0.53.0/mapbox-gl.css");
@import url("https://cdn.jsdelivr.net/npm/vue-mapbox@latest/dist/vue-mapbox.css");
.map {
  margin: auto;
  max-width: 30rem;
  height: 15rem
}
</style>
