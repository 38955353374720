<template>
  <section class="page-section" id="referentie">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase">Referentie</h2>
        <h3 class="section-subheading text-muted">Arianne Kortleve, directeur kinderopvangorganisatie Flexkidz</h3>
      </div>
      <div>
        <p class="text-muted">
          Wanneer Esther de locatie binnenkomt begint haar observatie meteen. Scherp en met een duidelijke en
          professionele blik kijkt ze naar de groep en de medewerkers.
        </p>
        <p class="text-muted">
          Veel dingen ziet ze meteen en worden direct doorvertaald naar coachvragen en leerwensen. Wat gebeurt er
          al wel en waar kunnen we nog groeien. Wat hebben we nodig om te kunnen groeien.
        </p>
        <p class="text-muted">
          Tijdens de gesprekken met medewerkers weet ze door veel vragen te stellen snel te achterhalen waar het
          struikelblok ligt en waar een coachee aan kan werken. De verantwoordelijkheid wordt bij de coachee
          neergelegd door Esther.
        </p>
        <p class="text-muted">
          Haar gedegen kennis betreffende pedagogiek en de taken van een pedagogisch medewerker zijn zeer van
          waarde en helpen om een leerwens om te zetten in een vaardigheid.
        </p>
        <p class="text-muted">
          Esther heeft een rugzak vol kennis en materialen om de coaching op een kundige en voor medewerkers leuke
          en verhelderende manier vorm te geven.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Reference"
}
</script>

<style lang="scss" scoped>
@import '../styles/styles.css';

.root {
  scroll-behavior: smooth;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
