import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueMaterial from 'vue-material'
import VueSilentbox from 'vue-silentbox'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faCircle} from "@fortawesome/free-solid-svg-icons/faCircle";
import {faSun} from "@fortawesome/free-solid-svg-icons/faSun";
import {faSadCry} from "@fortawesome/free-solid-svg-icons/faSadCry";
import {faCamera} from "@fortawesome/free-solid-svg-icons/faCamera";
import {faPoll} from "@fortawesome/free-solid-svg-icons/faPoll";
import {faRing} from "@fortawesome/free-solid-svg-icons/faRing";
import {faLinkedinIn} from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import {faShieldAlt} from "@fortawesome/free-solid-svg-icons/faShieldAlt";
import {faGraduationCap} from "@fortawesome/free-solid-svg-icons/faGraduationCap";
import {faBlackTie} from '@fortawesome/free-brands-svg-icons/faBlackTie'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import VueMeta from 'vue-meta'

import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome'
import 'vue-material/dist/vue-material.min.css'
import vuetify from './plugins/vuetify'
import './registerServiceWorker'
import SebCoaching from "@/pages/SebCoaching";
import Home from "@/pages/Home";

Vue.use(VueMeta)
Vue.use(VueRouter)
Vue.use(VueMaterial)
Vue.use(Buefy)
Vue.use(VueSilentbox)
Vue.material.locale.dateFormat = 'dd/MM/yyyy'
Vue.config.productionTip = false

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
library.add(faCircle, faSun, faSadCry, faCamera, faPoll, faBlackTie, faRing, faShieldAlt, faGraduationCap, faLinkedinIn)
Vue.component('font-awesome-icon', FontAwesomeIcon)

const routes = [
    {path: '/', component: Home},
    {path: '', component: Home},
    {path: '/coaching-met-seb', component: SebCoaching},
    {path: '*', redirect: '/'}

]
const router = new VueRouter({
    mode: 'history',
    routes: routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        }
    }
})

new Vue({
    render: h => h(App),
    vuetify,
    router
}).$mount('#app')
