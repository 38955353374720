<template>
  <section class="page-section" id="services">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase">Services</h2>
        <h3 class="section-subheading text-muted">Bij onder andere de volgende problemen kan ik helpen d.m.v.
          coaching:</h3>
      </div>
      <div class="row text-center">
        <div class="col-md-4">
          <span>
            <font-awesome-layers class="fa-stack fa-4x">
              <font-awesome-icon icon="circle" class="fas fa-stack-2x text-primary"/>
              <font-awesome-icon icon="sun" class="fab fa-stack-1x fa-inverse"/>
            </font-awesome-layers>
          </span>
          <h4 class="my-3">Hoogsensitiviteit</h4>
          <p class="text-muted"></p>
        </div>
        <div class="col-md-4">
          <span>
            <font-awesome-layers class="fa-stack fa-4x">
              <font-awesome-icon icon="circle" class="fas fa-stack-2x text-primary"/>
              <font-awesome-icon icon="sad-cry" class="fab fa-stack-1x fa-inverse"/>
            </font-awesome-layers>
          </span>
          <h4 class="my-3">(Faal) angst</h4>
          <p class="text-muted"></p>
        </div>
        <div class="col-md-4">
          <span>
            <font-awesome-layers class="fa-stack fa-4x">
              <font-awesome-icon icon="circle" class="fas fa-stack-2x text-primary"/>
              <font-awesome-icon icon="camera" class="fab fa-stack-1x fa-inverse"/>
            </font-awesome-layers>
          </span>
          <h4 class="my-3">Concentratiemoeilijkheden</h4>
          <p class="text-muted"></p>
        </div>
        <div class="col-md-4">
          <span>
            <font-awesome-layers class="fa-stack fa-4x">
              <font-awesome-icon icon="circle" class="fas fa-stack-2x text-primary"/>
              <font-awesome-icon icon="poll" class="fab fa-stack-1x fa-inverse"/>
            </font-awesome-layers>
          </span>
          <h4 class="my-3">Emotieregulatie</h4>
        </div>
        <div class="col-md-4">

          <span>
            <font-awesome-layers class="fa-stack fa-4x">
              <font-awesome-icon icon="circle" class="fas fa-stack-2x text-primary"/>
              <font-awesome-icon icon="sun" class="fab fa-stack-1x fa-inverse"/>
            </font-awesome-layers>
          </span>

          <h4 class="my-3">Rouw en verlies</h4>
          <p class="text-muted"></p>
        </div>
        <div class="col-md-4">
          <span>
            <font-awesome-layers class="fa-stack fa-4x">
              <font-awesome-icon icon="circle" class="fas fa-stack-2x text-primary"/>
              <font-awesome-icon :icon="['fab', 'black-tie']" class="fab fa-stack-1x fa-inverse"/>
            </font-awesome-layers>
          </span>
          <h4 class="my-3">Scheiding</h4>
          <p class="text-muted"></p>
        </div>
        <div class="col-md-12">
          <span>
            <font-awesome-layers class="fa-stack fa-4x">
              <font-awesome-icon icon="circle" class="fas fa-stack-2x text-primary"/>
              <font-awesome-icon icon="shield-alt" class="fab fa-stack-1x fa-inverse"/>
            </font-awesome-layers>
          </span>
          <h4 class="my-3">Weerbaarheid</h4>
          <p class="text-muted"></p>
        </div>
        <div style="margin: 50px"></div>
        <div class="col-md-12">
          <span>
            <font-awesome-layers class="fa-stack fa-4x">
              <font-awesome-icon icon="circle" class="fas fa-stack-2x text-primary"/>
              <font-awesome-icon icon="graduation-cap" class="fab fa-stack-1x fa-inverse"/>
            </font-awesome-layers>
          </span>
          <h4 class="my-3">Ik leer leren</h4>
          <h6 class="text-muted">Kosten groepstraining: €200,00 (inclusief werkboek & 21% btw)</h6>
          <span class="text-muted">Meer informatie over deze training: <a href="http://www.ikleerleren.nl">www.ikleerleren.nl</a>
          </span>
          <p class="text-muted"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Services"
}
</script>

<style scoped>
@import '../styles/styles.css';
</style>
