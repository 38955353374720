<template>
  <section class="page-section bg-light" id="introduction">
    <div class="container">
      <div class="text-center">
        <h2 class="section-heading text-uppercase">Over mij</h2>
        <h3 class="section-subheading text-muted">Mijn achtergrond</h3>
      </div>
      <div class="row">
        <div class="col-lg-4">
        </div>
        <div class="col-lg-4">
          <div class="team-member">
            <img class="mx-auto rounded-circle" src="../assets/img/team/4.jpeg" alt=""/>
          </div>
        </div>
        <div class="col-lg-4">
        </div>
      </div>
      <div class="container">
        <h4>Wie ben ik</h4>
        <p class="text-muted">
          Hallo! Mijn naam is <b>Esther Zaagmans</b> en ik woon in <b>Makkinga</b>. Hier woon ik in een prachtige
          landelijke omgeving samen
          met mijn partner en een aantal (huis-) dieren. Ik heb mijn hele leven gewerkt met kinderen. Kinderen
          zijn open en
          eerlijk en hebben een aangeboren nieuwsgierigheid en leergierigheid. In alle jaren dat ik met
          kinderen
          heb samengewerkt heb ik heel veel van hen mogen leren.

        </p>
        <br><br>
        <h4>Visie</h4>
        <p class="text-muted">
          Waar de wereld aan de ene kant een uitdagende leeromgeving is voor kinderen, is deze wereld soms
          ook ingewikkeld en ongrijpbaar. De ontzettende hoeveelheid informatie die op ze af komt en
          de prestatiedruk die veel kinderen ervaren maakt dat sommige kinderen uiteindelijk soms tijdelijk vast
          lopen. Ze hebben dan een duwtje nodig in de goede richting waarna ze weer zelfstandig verder kunnen.
          <br><br>
          Als kindercoach verzorg ik voor die kinderen die tijdelijk vastlopen korte interventies waarin we samen
          kijken naar de uitdaging die het kind ervaart. Ik werk oplossingsgericht en gebruik vaak (spel-)
          materiaal om de uitdaging niet alleen te benoemen, maar ook visueel te maken. Het visueel maken van een
          uitdaging
          maakt dat je zichtbaar en tastbaar kan oefenen met de uitdaging die het kind ervaart.
        </p>
        <br><br>
        <h4>Kwalificaties & Certificeringen</h4>
        <p class="text-muted">
          Ik ben pedagogisch geschoold en heb kennis van de verschillende actuele pedagogische stromingen en
          denkbeelden. Ik heb een hele brede interesse en school me elk jaar bij op de verschillende gebieden. Ik
          vind het belangrijk om mijn kennis actief bij te houden.
          <br><br>
          Mijn opleiding tot kindercoach heb ik gevolgd
          bij <a style="color: #1176cf;" href="https://teaadema.nl/">Centrum Tea Adema</a>. Binnenkort volg ik hier nog
          de module
          "systemisch/contextueel werken". Dit sluit weer aan bij de
          pedagogische denkbeelden van Nagy. Ik werk vanuit de gedachte dat een systeem waarin het kind groot
          wordt, heel veel invloed heeft en dat soms een hele kleine verschuiving in dat systeem kan leiden tot
          positieve verandering bij het kind.
          <br><br>
          Verder ben ik <a style="color: #1176cf;" href="https://www.hkz.nl/zzp/">HKZ-gecertificeerd</a>.
          Dit betekent dat ik voldoe aan de uitganspunten voor kwaliteit die voor zorg gelden en ben beoordeeld
          op de betrouwbaarheid van mijn administratie en het werken volgens de principes van een kwaliteitsmanagementsysteem.
        </p>
        <div class="col">
          <img src="../assets/img/HKZ-cert.png" class="hkz-image" alt="HKZ Logo">
        </div>
        <br><br>
        <h4>Overige ervaring</h4>
        <p class="text-muted">
          Naast mijn werk als kindercoach werk ik ook als pedagogisch coach op verschillende locaties van de
          kinderopvang <a style="color: #1176cf;" href="http://www.flexkidzkinderopvang.nl/">Flexkidz</a>. Hier coach ik
          pedagogisch
          medewerkers op de werkvloer en samen met deze
          pedagogisch medewerkers draag ik zorg voor een veilige omgeving voor het kind. Daarnaast kijken we ook
          naar hoe we een kind het best kunnen ondersteunen in zijn/haar ontwikkeling en helpen we de kinderen
          hierbij
          door middel van een uitdagende speelleeromgeving.
          <br><br>
          Verder ben ik in bezit van een geldig VOG welke doorlopend gecontroleerd wordt. Ook beheers ik actief
          het Fries en Nederlands.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Introduction"
}
</script>

<style scoped>
@import '../styles/styles.css';

.hkz-image {
  width: 200px;
  display: block;
  margin: auto;
  padding: 10px;
}

</style>
