<template>
  <footer class="footer py-4">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-4 my-3 my-lg-0">
          <a class="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/esther-zaagmans-8b130561/"><i
              class="fab fa-linkedin-in"></i>
            <font-awesome-icon :icon="['fab', 'linkedin-in']" class="fab fa-stack-1x fa-inverse"/></a>
        </div>

      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "SocialMedia"
}
</script>

<style lang="scss" scoped>
@import '../styles/styles.css';

</style>
